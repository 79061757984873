hr {
  display: block;
  border: 1px solid $userRole;
  width: 100%;

}

.userRole {
  color: $userRole;
  text-align: right;
  // font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.title {
  color: $black;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}

.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tableContainer {
  width: 1220px;
  height: 857px;
  flex-shrink: 0;
  border-radius: 50px;
  background: $whiteColor;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
}


.table {
  padding: 40px;
}

.td {
  font-size: 16px;
  font-weight: 500;
}

.date {
  font-size: 12px;
  font-weight: 500;
}

.reedemBtn {
  display: flex;
  width: 85px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--Linear, linear-gradient(90deg, #183fea 0%, #1632ac 100%));
  padding: 10px;
  color: $whiteColor;
}

.viewPhoto {
  padding: 15px;
  display: flex;
  min-width: 105px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $black;
}

.timeZone {
  min-width: 100px;
  margin: 0 20px;
}

.webcam-container {
  width: 720px;
  height: 720px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

}

// .web-cam-modal{
//   .modal-content{
//     width: 1000px;
//   }
// }
.overlay {
  width: 400px;
  height: 400px;
  --border-style: 3px solid red;
  --border-space: 20px;
}

.overlay-element {
  position: absolute;
  width: 100px;
  height: 100px;
}

.overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: 70px;
  left: 60px;
}

.overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: 70px;
  right: 60px;
}

.overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: 90px;
  left: 60px;
}

.overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: 90px;
  right: 60px;
}

.winners-list {
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

    .download {
      display: flex;
      align-items: center;
      gap: 20px;

      .download-btn {

        .dropdown-toggle {
          padding: 5px 15px;
          border-radius: 12px;
          background-color: $search-field;
          cursor: pointer;
          color: $black;
          border: 1px solid $search-field !important;

          &:hover {
            background-color: transparent;
          }
        }

        .download-all {
          padding: 5px 15px;
          border-radius: 12px;
          cursor: pointer;
          background-color: transparent;
          border: 1px solid $search-field !important;
          color: $black;

          &:hover {
            background-color: $search-field;
          }
        }
      }
    }

    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }
}

.winners-list {
  .select-drop {
    border-radius: 10px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
    width: 315px;
  }
}

@media only screen and (max-width: 1640px) {
  .winners-list {
    .actions {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }
  }
}