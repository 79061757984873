//font size
.f-size-12 {
    font-size: 12px;
    line-height: 15px;
}

.f-size-13 {
    font-size: 13px;
    line-height: 16px;
}

.f-size-14 {
    font-size: 14px;
    line-height: 17px;
}

.f-size-16 {
    line-height: 20px;
    font-size: 16px;
}

.f-size-17 {
    font-size: 17px;
    line-height: 21px;
}

.f-size-18 {
    font-size: 18px;
    line-height: 20px;
}

.f-size-20 {
    font-size: 20px;
    line-height: 24px;
}

.f-size-22 {
    font-size: 22px;
    line-height: 27px;
}

.f-size-24 {
    font-size: 24px;
    line-height: 29px;
}

//font weight
.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

// Margin style
.mb-30 {
    margin-bottom: 30px;
}

.page-title {
    font-weight: 600;
    font-size: $xxxlarge;
    line-height: 20px;
    color: #393939;
    margin: 0;
    padding-bottom: 30px;

}

.card {
    margin-bottom: 24px;
    background: #fff;
    border-radius: 15px;
}

.title-grp {
    @include justify-between;
    padding-bottom: 10px;

    h2 {
        padding-bottom: 0;
    }

}

.search-box {
    margin-right: 20px;
}

//Select Dropdown Stayle
.select-search-container {
    width: 100%;

    .select-search-input {
        height: 37px;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding: 0.47rem 0.75rem;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-gray-700);
        background-color: var(--bs-custom-white);
        background-clip: padding-box;
        border: 1px solid var(--bs-input-border-color);
        min-height: 53px;
    }

    .select-search-options {
        padding-left: 0;
    }

    .select-search-select {
        top: 40px;
        border-width: 1px;
    }

    .select-search-option:not(.select-search-is-selected):hover {
        background: #516bfb;
        color: #fff;
    }

    .select-search-option {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-gray-700);
    }
}

input {
    &:disabled {
        cursor: no-drop;
        border-color: transparent;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.countdown-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 5px;

    p {
        margin: 0;
        padding-right: 10px;
    }
}

.select-down-arrow {
    position: absolute;
    right: 7px;
    top: 15px;
    z-index: 1;

    .bx-chevron-down {
        font-size: 25px;
        border-left: 1px solid #ced4da;
        padding-left: 2px;
    }
}

.form-label {
    span {
        color: $primary-color;
    }
}

// NO-data


#datanotfound {
    width: 450px;
    height: 450px;
    margin: 0 auto;
}

// PageLoader
.loaderoverlay {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 33;
    background: #ffffffc4;
    left: 0;
    top: 0;

    .icon {
        background: white;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

body[data-layout-mode="dark"] {
    .loaderoverlay {
        background: rgba(0, 0, 0, 0.452);
        box-shadow: 0px 5px 14px black;

        .icon {
            background: #32394e;
        }
    }
}

.pageloader {
    width: calc(100px - 14px);
    height: 50px;
    border-radius: 50px;
    background:
        radial-gradient(farthest-side, #0000 calc(100% - 15px), #ccc calc(100% - 14px) 99%, #0000) left,
        radial-gradient(farthest-side, #0000 calc(100% - 15px), #ccc calc(100% - 14px) 99%, #0000) right;
    background-size: calc(50% + 7px) 100%;
    background-repeat: no-repeat;
    position: relative;
    animation: loading-0 2s infinite linear;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #DF0C00;
        transform-origin: -14px 50%;
        animation: loading-1 0.6s infinite linear;
    }
}

@keyframes loading-0 {

    0%,
    49.99% {
        transform: scaleX(1)
    }

    50%,
    100% {
        transform: scaleX(-1)
    }
}

@keyframes loading-1 {
    100% {
        transform: rotate(1turn)
    }
}

// Tables Style
.custom-header-css {
    tr {
        td {
            padding: 8px 20px;
            vertical-align: middle;
        }
    }
}

.table-select {
    select {
        width: 10%;
    }
}

.table> :not(caption)>*>* {
    padding: 8px 20px;
    vertical-align: middle;
}

td.reset-expansion-style.expanding-foo {
    background: #f2f2f2;
    transition: all 0.5s ease-in-out;
    position: relative;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 350px;

    img {
        max-width: 100px;
    }
}

// Modal Style
.modal {
    .modal-content {
        padding: 20px;

        .modal-body {
            padding: 0;

            .content {
                // display: flex;

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 6px;
                }

                p {
                    font-size: 14px;
                }

                .text {
                    padding-left: 20px;
                }

                .icon {
                    // margin-right: 20px;
                    width: 42px;
                    height: 40px;
                    border-radius: 50px;
                    background: rgba(0, 128, 0, 0.384);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        padding: 10px;
                    }
                }
            }

            &.deactive {
                .icon {
                    background: rgba(255, 0, 0, 0.493);
                }
            }
        }

        .btn-close {
            position: absolute;
            top: 20px;
            left: auto;
            right: 20px;
            z-index: 2;
        }

        .modal-footer {
            padding: 0;

            button {
                margin: 0 4px;
            }
        }
    }

    .profile {
        .modal-content {
            padding: 0;
            margin: 0;
            border: none;
        }

        .btn-close {
            filter: invert(100%);
        }

        .modal-header {
            height: 100px;
            background: $primarygradient;
            position: relative;

            .avatar-img {
                position: absolute;
                margin: 0 auto;
                bottom: -35px;
                left: 42%;

                span {
                    font-size: 24px;
                    border: 2px solid $white;
                }
            }
        }

        .modal-body {
            padding-top: 45px;

            .user-name {
                font-size: 20px;
                font-weight: bold;
            }

            .user-info {
                .d-flex {
                    align-items: center;

                    label {
                        margin-bottom: 0;
                        font-size: 14px;
                    }
                }

                .icon-filed {
                    padding: 8px;
                    // background-image: -webkit-linear-gradient(179deg, rgb(131, 0, 0) 0%, rgb(241, 18, 0) 100%);
                    border-radius: 25px;

                    img {
                        width: 20px;
                    }
                }

            }
        }
    }
}

.profile-edit {
    position: relative;

    .edit-icon {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 25px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;

        img {
            width: 14px;
        }
    }
}


div.switch-tab {
    width: auto;
    border-radius: 50px;

    button {
        font-size: 14px !important;
        font-weight: 600;
        border: 1px solid $gray-600;
        color: $gray-600;

        &.btn {

            &.select {
                background: $gray-600;
            }
        }

        &:hover {
            background: $gray-600;
            border: 1px solid $gray-600;
        }

        &:first-child {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;

            &:active {
                background: #FFF;
                border: 1px solid $gray-600;
            }
        }

        &:last-child {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;

            &:active {
                background: #FFF;
                border: 1px solid $gray-600;
            }
        }
    }
}

// .request-quotes {
//     .btn-draft {
//         height: 35px;
//     }

//     .quotes-button-class {
//         div {
//             button {
//                 margin: 0 4px;
//             }
//         }
//     }

//     .shipment-type-radio {
//         display: flex;
//         justify-content: space-around;
//         align-items: center;
//         margin-top: 35px;
//     }

//     input[type="radio"] {
//         background: $white;
//         border: 2px solid $white;
//         outline: 3px solid $primary-color;

//         &:checked {
//             background: $primary-color;
//         }
//     }

//     .react-datepicker-wrapper {
//         position: relative;
//         input {
//             z-index: 0;
//             min-height: 38px;
//         }
//         &::after{
//            content: '';
//            width: 20px;
//            height: 20px;
//            position: absolute;
//            top: 15px;
//            right: 12px;
//            background-image: url("../../../images/svg/calendar.svg");
//            background-repeat: no-repeat;
//            background-size: cover;
//         }
//     }

//     textarea {
//         min-height: 118px;
//         height: calc(100% - 43px);
//         resize: none;

//         &.is-invalid {
//             height: calc(100% - 62px);
//         }
//     }

//     .cargoType-radio-btn {
//         border-radius: 50px !important;

//         .cargo-item {
//             label {
//                 font-size: 14px !important;
//             }
//         }

//         input:checked+label {
//             background-color: $gray-600 !important;
//         }
//     }

//     .value-card {
//         background: rgba(255, 0, 0, 0.205);
//         border-radius: 4px;
//         text-align: center;
//         padding: 12px 8px;

//         .value {
//             padding: 4px 8px;
//             background: #fff;
//             border-radius: 25px;
//         }
//     }

//     .close-icon {
//         position: absolute;
//         top: 0;
//         right: 0;
//         z-index: 1;
//     }
// }


// Button style 
//primary button style
// .primary-button {
//     background: $primarygradient;
//     border-radius: 30PX;
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 14px;
//     min-width: 122px;
//     padding: 5px 20px;
//     height: 35px;
//     border: 1px solid $primarygradient;
//     color: $white;

//     &:hover {
//         border: 1px solid $primary-color !important;
//         background: transparent !important;
//         color: $primary-color !important;
//     }

//     &:active {
//         border: 1px solid $primary-color !important;
//         background-color: transparent !important;
//         color: $primary-color !important;
//     }
// }

// .secountry-btn {
//     padding: 5px 8px;
//     border: 1px solid $primary-color;
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 14px;
//     color: #DF0C00;
//     border-radius: 25px;
//     background: transparent;
//     min-width: 122px;
//     height: 35px;
//     outline: none;
//     box-shadow: none !important;

//     &:hover {
//         border: 1px solid $primary-color !important;
//         background: $primarygradient !important;
//         color: $white !important;
//     }

//     &:active {
//         border: 1px solid $primary-color !important;
//         background: $primarygradient !important;
//         color: $white !important;
//     }
// }
.btn-draft {
    border-radius: 50px;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;

    &:hover {
        background: transparent;
        border: 1px solid $gray-600;
        ;
        color: $gray-600;
    }
}

//pagination

.pagination {
    .action-btn {
        padding: 0;

        button {
            background: transparent;
            padding: 0;
            border: 0;
            color: $primary-color;
            font-size: 22px;
            cursor: pointer;

            &:active {
                background: transparent;
                color: $primary-color;
            }

            &.disabled {
                color: $disable-color;
            }
        }
    }

    input {
        text-align: center;
    }
}

.form-control {
    border-color: $white;
    border-radius: 10px;
    height: 53px;

    &.is-invalid {
        background-image: none;
    }

    &:focus {
        border-color: $white;
    }
}

// Error Message
.invalid-feedback {
    padding-left: 18px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        background: url('../../../images/svg/alert-circle.svg');
        background-size: cover;
    }
}

// File Upload 
.field-upload {
    input {
        display: none;
    }

    .upload-field {
        padding: 10px;
        width: 100%;
        border: 2px dotted $primary-color;
        min-height: 53px;
        cursor: pointer;
        color: $primary-color;
        font-size: 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // flex-direction: column;

        span {
            font-size: 20px;
            background: $light-primary;
            border-radius: 50px;
            font-weight: 600;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

        }

        &.is-invalid {
            span {
                background: $white;
            }
        }
    }

    &.upload {
        .upload-field {
            display: none;

            &.view-file {
                display: flex;
                justify-content: space-between;

                div {
                    align-items: center;
                }
            }
        }
    }
}

// User card details
.user-card-view {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .user-details {
        .user-info {
            display: flex;
            align-items: center;

            .user-bio {
                padding-left: 12px;
                text-align: left;

                .ellipsis-text {
                    margin: 0;
                }

                .email-field {
                    max-width: 250px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .badge-pill-role {
                border-radius: 25px;
                background: $badge-color-bg;
                border-radius: 25px;
                white-space: inherit;

                span {
                    color: $badge-color-color;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 16px;
                    line-height: 16px;
                    text-overflow: ellipsis;
                    display: block;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;

                }

            }

            .admin-card-footer {
                align-items: center;

                .user-bio {
                    padding-left: 8px;
                    text-align: left;

                    .ellipsis-text {
                        margin: 0;
                    }
                }

                .badge-pill {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                }

                .admin-card-footer {
                    align-items: center;
                }
            }

            .avatar {
                .border-line {
                    border: 2px solid $border-color-line;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 10px;
                        right: 0;
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        background: $active-color;
                        box-shadow: 0px 0px 0px 2px $white;
                    }
                }


                &.active {
                    .border-line {
                        border-color: $active-color;
                    }
                }

                &.deactive {
                    .border-line {
                        border-color: $deactive-color;

                        &::after {
                            background: $deactive-color;
                        }
                    }
                }

            }
        }

        .card-footer {
            padding: 0 !important;
            border: 0 !important;
            background: $card-footer-bg !important;

            .admin-card-footer {
                padding: 13px 0;

                a {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border-right: 1px solid $border-color-line;

                    &:last-child {
                        border: 0px;
                    }
                }
            }
        }

        &.deactive {
            .card-footer {
                .admin-card-footer {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);

                    a {
                        width: auto;
                    }
                }

            }
        }
    }

    &.skeleton {
        .user-details {
            .admin-card-footer {
                padding: 10px 20px !important;
                justify-content: space-between !important;
            }
        }
    }
}

.skeleton-table {
    table {
        width: 100%;

        thead {
            border-bottom: 1px solid $border-color-line;

            th {
                padding: 8px 5px;
            }
        }

        tbody {
            td {
                padding: 8px 5px;
            }
        }
    }
}

.error-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    padding: 20px;
    background: rgba(0, 0, 0, 0.329);

    .account-pages {
        height: calc(100vh - 40px);
        max-width: 88%;
        margin: 0 auto !important;
    }

    .btn-primary {
        background: $primarygradient !important;
        border: 0 !important;
    }
}

.notification-msg {
    .d-flex {
        align-items: center;
    }

    .lable-name {
        background: $badge-color-bg;
        color: $badge-color-color;
        padding: 4px 10px;
        border-radius: 50px;
    }
}

.no-notification {
    .simplebar-content-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.notification-icon {
    background: $light-primary !important;

    img {
        max-width: 18px !important;
    }
}

textarea {
    resize: none !important;
}

//settings
.settings {
    .settings-action {
        height: 10px;
    }

    .addressInfo-res {
        display: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.settings-card .rmdp-ep-arrow[direction=top] {
    border-top: 0 !important;
}

.settings-card .rmdp-input {
    border: 0 !important;
    border-radius: 10px !important;
    height: 53px !important;
    margin: 1px 0;
    padding: 10px 15px !important;
}