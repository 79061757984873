//
// _buttons.scss
//
button:disabled {
  opacity: 0.4;
  pointer-events: none;
}

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;
  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}

.btn-group-vertical {
  label {
    margin-bottom: 0px;
  }
}

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;
  transition: all 0.5s ease;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
    border-color: transparent;
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.like-btn {
  &.active {
    .bx-heart {
      color: $danger;
      &:before {
        content: "\ec8f";
      }
    }
  }
}
.btn-common {
  @include borderRadius_30;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  min-width: 122px;
  height: 40px;
  @include flex-center;
  text-transform: capitalize;
  &.disabled{
    background: #ccc !important;
    &:hover{
      background: #ccc !important;
      border: 0 !important;
      color: #fff !important;
    }
  }
}
.primary-button {
  background: $primarygradient;
  padding: 5px 20px;
  border: 0;
  border: 1px solid transparent;
  color: $white;
  //  @include borderRadius_30;
  border-radius: 12px;
  &:hover {
    border: 1px solid $gradientsecondary !important;
    background: transparent !important;
    color: $gradientsecondary !important;
  }

  &:active {
    border: 1px solid $gradientsecondary !important;
    background-color: transparent !important;
    color: $gradientsecondary !important;
  }
}

.secountry-btn {
  padding: 5px 8px;
  border: 1px solid $gradientsecondary;
  color: $gradientsecondary;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 12px;
span{
    color: $gradientsecondary;
}
  &:hover {
    border: 0px solid $primary-color !important;
    background: $primarygradient !important;
    color: $white !important;
    span{
        color: $white;
    }
  }
  &.disabled{
    background: #fff !important;
    color: $gradientsecondary !important;
    opacity: 0.3;
    &:hover{
      border: 1px solid $gradientsecondary !important;
      background: #fff !important;
      opacity: 0.3;
      span{
        color: $gradientsecondary !important;
      }
    }
  }

  &:active {
    border: 1px solid $gradientsecondary !important;
    background: $primarygradient !important;
    color: $white !important;
  }
}

.btn-group {
  .primary-button {
    margin-right: 15px;
  }
}
