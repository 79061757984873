@media only screen and (max-width: 1560px) {
    .user-card-view {
        grid-template-columns: repeat(3, 1fr);

    } 
    
}
@media only screen and (max-width: 1350px) {
.tracking-status-row{
    .col-md-8{
        .col-md-3{
            width: 100%;
        }
        .col-md-9{
            width: 100%;
        }
    }
}
}
@media only screen and (max-width: 1290px) {
    div {
        &.value-col {
            width: 44%;
        }
    }

    .user-card-view {
        grid-template-columns: repeat(2, 1fr);

    }
}

@media only screen and (max-width: 1200px) {
    .cargo-types {
        width: 75%;
    }
}

@media only screen and (max-width: 998px) {

    .account-pages {
        padding: 40px 0;
        .auth-logo{
             img{
                width: 200px;
             }
           }
    }
    #page-header-search-dropdown{
        display: none;
    }
    
    .settings {
        .addressInfo {
            display: none;
        }
    
        .addressInfo-res {
            display: block;
        }
    }
}
 

@media only screen and (max-width: 760px) {
    div {
        &.value-col {
            width: 100%;
            padding-top: 20px;
        }
    }

    .user-card-view {
        grid-template-columns: repeat(1, 1fr);

        .user-details {
            .user-info {
                flex-direction: column;

                .user-bio {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }
    }

    .shipper-details{
        .col-first{
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .filter-section {
        flex-direction: column;
        align-items: end;
        margin-bottom: 0px !important;

        .action-field {
            width: 100%;
            margin-bottom: 10px;
        }

        .field {
            width: 50%;
        }
    }

    .booking {
        .tract-info {
            .card-body {
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }
        }

        .status-list {
            .status {
                .status-details {
                    flex-direction: column;
                    padding-bottom: 40px;
                }
            }
        }

    }

    .progress-step {
        flex-direction: column;

        .step {
            width: 100%;
            padding-bottom: 40px;

            &::before {
                left: 28px;
                width: 5px;
                height: 100%;
            }
            &:last-child{
                width: 100%;
            }
        }

        .content {
            display: flex;

            span {
                margin-right: 25px;
            }
        }
    }
    .booking{
        .container-info {
            .card-body{
                grid-template-columns: repeat(1, 1fr);
                display: flex;
                align-items: center;
            }
        }
    }
    .pagination{
        max-width: 150px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 530px) {
    .booking {

        .status-list {
            .day-date {
                width: 20px;

                p {
                    display: none;
                }
            }

            .day-date-mobile {
                width: 100%;
                display: block;
            }

            .status {
                .status-details {
                    .title {
                        display: flex;

                        span {
                            display: block;
                        }
                    }
                }

                .time {
                    width: 20px;

                    p {
                        display: none;
                    }
                }
            }
        }
         
            .tract-info {
                .card-body{
                    grid-template-columns: repeat(1, 1fr);
                  
                }
            }
        
    }

    .error-screen{ 
        .account-pages{
            .action-btn{
                flex-direction: column;
                div{
                    margin-bottom: 15px ;
                   a{
                        width: 100%;  
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .shipper-details {
        .action-btn {
            flex-direction: column;

            button{
                margin-right: 0 !important;
            }
        }
    }

    .profile-screen {
        .pwd-change {
            margin-top: 25px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .filter-section {
        .action-field {
            flex-direction: column;
            margin-bottom: 0px;

            .field {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .cargo-types {
        width: 100%;
    }
    
    .filter-section{
        .drop-down {
            margin-right: 0;
            margin-bottom: 20px;
        }
        .user-dropdown {
            [class*="-control"] {
                width: 100%;
            }
        }
    }
    
}

@media only screen and (max-width: 375px) {
    .request-quotes {
        .quotes-button-class {
            div {
                button {
                    &:last-child {
                        margin-top: 8px;
                    }
                }
            }
        }
    }

}