.faq {
    .card {
      padding: 15px;
      .card-body {
        padding: 0;
      
      }
    }
    .custom-header-css th.Header {
        text-align: left;
        padding-left: 15px;
    }
    table {
        tbody{
            width: 200px !important;
        }
      margin-bottom: 47px;
      th {
        text-align: left;
      }
      tr {
        td {
        &.arrow-icon{
          width: 200px;
        }
          div {
            justify-content: center;
  
            .view-faq-icon,
            .edit-faq-icon,
            .delete-faq-icon {
              color: $primary-color; 
              cursor: pointer;
            }
          }
        }
      }
    }
  // .text-area{
  //   height: 200px;
  // }

  }
  
  .view-Faq{
    
    .manage-card {
        .crd-details {
          &.text-area{
            height: 200px;
          }
            grid-template-columns: repeat(3, 1fr);
            padding-bottom: 34px;
        }
    }
    .faq-body{
        padding: 34px;
    }
    
}