.design-tool-container {

    .default-option {
        display: flex;
        padding: 30px;
        padding-bottom: 0;
        justify-content: space-between;
        margin-left: 100px;

        .color-font {
            font-size: 15px;
            font-weight: 800;
        }

        .align-center {
            text-align: center;

            input {
                height: 26px;
                padding: 3px;
                border-radius: 5px;
            }

            select {
                height: 26px;
                padding: 3px;
                border-radius: 5px;
                border: none;

                option {
                    padding: 25px;
                }
            }
        }
    }

    .more-tools {
        display: flex;
        column-gap: 10px;
        align-items: center;

        h6 {
            font-size: 15px;
            font-weight: 600;

        }

    }

    .icons-only {
        gap: 0;
    }

    .add-border {
        border-bottom: 1px solid $sidebar-menu-item-color;
        border-radius: 10px;
    }

    .option {
        padding: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        cursor: pointer;
        position: relative;

        &.active {
            background-color: $sub-table;
            border-radius: 10px;
        }

        &.disable {
            opacity: 0.2
        }
        &:hover .tooltip-text {
            visibility: visible;
            opacity: 1;
        }
    }

    .option .tooltip-text {
        visibility: hidden;
        background-color: $tooltip-color-bg;
        color: $whiteColor;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
    }

    .option .tooltip-text::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $tooltip-color transparent transparent transparent;
    }

    


    .design-tool {
        padding: 30px;
        display: flex;


        .tool-sec {
            border-radius: 15px;
            margin-right: 30px;

            .option {
                margin-bottom: 15px;

                img {
                    height: 40px;
                    width: 40px;
                }

                h6 {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 0;
                    text-align: center;
                }
            }
        }

        .relative-parent {
            position: relative;

            .select-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100% !important;
                margin-bottom: 15px;

                h5 {
                    font-weight: 600;
                    text-transform: lowercase;
                    &::first-letter{
                        text-transform: uppercase;
                    }
                }
            }

            .closeArrow {
                cursor: pointer;
            }

            .tools-option {
                padding: 25px;
                width: 350px;
                position: absolute;
                z-index: 5;
                height: 100%;
                background-color: $whiteColor;
                border-radius: 10px;
                border: 1px solid $tooltip-option;

                .image-template {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 15px;
                    margin-bottom: 15px;

                    img {
                        height: 150px;
                        width: 100%;
                        border-radius: 10px;
                        background-color: $tiltes-bg;
                        cursor: pointer;

                    }
                }
            }
        }


        .canvas-sec {
            border-radius: 25px;
            width: 100%;

            .canvas-container {
                padding: 25px;
                background-color: $sub-table;
                border-radius: 25px;

            }


        }
    }

    .select-menu {
        [class*="-indicatorSeparator"] {
            display: none;
        }

        [class*="-is-selected"] {
            background: $primarygradient;
            color: $whiteColor;
        }

        [class*="-menu"] {
            border-radius: 10px;
            border: 0;
            box-shadow: 0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

            [class*="-option"] {
                cursor: pointer;
                padding: 8px 15px;
                text-transform: capitalize;

                &:hover {
                    background-color: linear-gradient(90deg, $gradientprimaryLight 0%, $gradientsecondaryLight 100%);
                }

            }

            [class*="-is-focused"] {
                background-color: $primarygradientLight;
            }

            [class*="-MenuList"] {
                padding-top: 10px;
                padding-bottom: 10px;

            }
        }

        [class*="-control"] {
            font-size: 14px;
            border-radius: 10px;
            border: none;
            white-space: nowrap;
            text-overflow: clip;
            width: 100%;
            cursor: pointer;
            box-shadow: none;
            padding: 2px 0px 0 10px;
            background: $whiteColor;
            font-weight: 500;
            height: 40px;
            border-bottom: 1px solid;
        }

        [class*="-singleValue"] {
            text-transform: capitalize;
        }
    }

    .select-menu {
        width: 180px;

        &.fs-size {
            width: 100px;
        }
    }

    .color-input {
        height: 15px;
        border: none;

        &::before {
            content: "A";
            position: absolute;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            width: 60%;
            top: -12px;

        }
    }

    .tools-option {
        .custom-file-upload {
            display: inline-flex;
            padding: 10px;
            cursor: pointer;
            background-color: $sub-table;
            color: $sidebar-menu-item-color;
            border-radius: 5px;
            border: 1px solid $sub-table;
            gap: 8px;
            align-items: center;
            img{
                width: 22px;
            }
        }

        .custom-file-upload:hover {
            background-color: $sidebar-menu-item-active-color;
            color: $whiteColor;
            img{
                filter: invert(1);
            }
        }

        .custom-file-upload input[type="file"] {
            display: none;
        }
    }
}