hr {
  display: block; 
  border: 1px solid $userRole;
  width: 100%;
}

.userRole {
  color:$userRole;
  text-align: right;
  // font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.title {
  color:$black;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}
.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tableContainer {
  width: 1220px;
  height: 857px;
  flex-shrink: 0;
  border-radius: 50px;
  background: $whiteColor;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
}
.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 40px 0 40px;
}
.searchField {
  width: 275px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  background:$search-field;
  display: flex;
  align-items: center;
}
.searchIcon {
  margin-left: 15px;
}
.searchInput {
  background:$search-field;
  padding: 10px;
  border: none;
  width: 85%;
  margin-left: 0px;
  display:inline-block !important;
}
.table {
  padding: 40px;
}
.td {
  font-size: 16px;
  font-weight: 500;
}
.date {
  font-size: 12px;
  font-weight: 500;
}
