.confirmation {
    .modal-content {
        padding: 20px 25px;
        @include borderRadius_15;

        .modal-header {
            .close-icon {
                position: absolute;
                right: 15px;
                top: 15px;
                cursor: pointer;

                .bx {
                    font-size: $xxxlarge;
                }
            }
        }

        .primary-button {
            margin-right: 15px;
        }
    }

    .head-item {
        @include justify-between;
        width: 100%;
    }

    .modal-body {
        .upload-container {
            border: 2px dashed grey;
            padding: 20px;
            text-align: center;
            cursor: pointer;
            position: relative;
            left: 0;
            @include border-radius(20px);
            height: 210px;

            input {
                opacity: 0;
                position: absolute;
                width: 100%;
                left: 0;
                height: 100%;
                top: 0;
                cursor: pointer;
            }

            .upload-icon {
                @include box-size(60px, 60px);
                background:$primary-grey;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                margin: 0 auto;
            }

            .upload-content {
                @include flex-center-column;
                justify-content: center;
                height: 100%;

                h5 {
                    margin-top: 15px;
                    margin-bottom: 8px;
                }
            }

        }
    }

}


@media (min-width: 576px) {
    .confirmation {
        &.modal-sm {
            --bs-modal-width: 460px;
        }

        .form-group {
            margin: 15px 0;

            .form-control {
                background: $content-bg;
            }
        }
    }

}