//
// Contacts.scss
//
.contact-links {
  a {
    color: var(--#{$prefix}body-color);
  }
}

.is-invalid {
  background: $error-bg;
}

// // profile
// .profile-user-wid {
//   margin-top: -15px;
// }

.search-box {
  .form-control {
    border-radius: 10px;
    padding-left: 40px;
    border: none;
  }
}

.search-box {
  .search-icon {
    font-size: 16px;
    left: 13px;
    line-height: 38px;
    position: absolute;
    top: 10px;
  }
}

.dots-admin-list {
  position: absolute;
  right: 5px;
  top: 5px;
}

.option-btn,
.option-btn:hover {
  background: transparent !important;
  border: none;
  color: #5e5a5a;
  font-size: 20px;
}

.search-addNew-div {
  margin-bottom: 15px;

  .form-control {
    padding-right: 30px;
    border: 0;
    background: $whiteColor;
  }

  .filter-section {
    align-items: center;
  }

  .multiSelectContainer {
    .search-wrapper {
      background: $whiteColor;
      box-shadow: none;
      border-radius: 10px;
      overflow: hidden;
      padding: 0;
      display: flex;
      align-items: center;
      min-height: 53px;

      .singleChip {
        display: block;
        white-space: nowrap;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 14px;
      }

      input {

        padding: 0 14px;
      }
    }

    &::before {
      top: 20px;
    }

    .optionContainer {
      border: 0;
      box-shadow: 0 0.0625rem 0.125rem #00000026;
    }
  }
}

body[data-layout-mode="dark"] {
  .search-addNew-div {
    .form-control {
      box-shadow: 1px 2px 3px black;
    }
  }
}

.admin-card-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;

  i {
    font-size: 20px;
    color: $icon-grey;
  }

  .bx-edit-alt {
    color: $primary-color;
  }
}

.content {
  .avatar-img {
    height: auto;
    width: auto !important;
    display: flex;
    justify-content: center;

    .avatar-txt {
      height: 100px;
      width: 100px;
      font-size: 45px;
    }
  }
}

.search-addNew-div {
  .select-search-container {
    width: 210px;
    margin-right: 10px;

    input {
      cursor: pointer;
      padding: 8px 30px 8px 10px;
    }
  }
}

.search-addNew-div {
  .filter {
    width: 100%;
    height: 36px;
    border: 1px solid;
    border-radius: 5px;

    .filter-items {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      position: relative;

      .filter-name {
        background: #485ec4;
        width: fit-content;
        color: #ffffff;
        padding: 2px 10px;
        border-radius: 8px;
        margin-right: 10px;

        span {
          color: #bdbdbd;
          font-weight: 600;
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .close-all-filter {
        color: #736f6f;
        position: absolute;
        right: 10px;
        font-weight: 600;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
}


.account-status {
  position: relative;

  p {
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    color: hsl(0, 0%, 100%);
    right: -28px;
    top: -20px;
    padding: 5px 15px;

    &::after {
      content: '';
      position: absolute;
      right: 5px;
      top: 18px;
      width: 25px;
      height: 25px;
      z-index: -1;
      background: $red;
      transform: rotate(35deg);
    }
  }

  &.active {
    p {
      background: $green;

      &::after {
        background: $green;
      }
    }
  }

  &.in-active {
    p {
      background: $red;

      &::after {
        background: $red;
      }
    }

  }
}

.role {
  .select-search-input {
    background: $error-bg;
    border: 1px solid $error-color !important;
    padding-right: calc(1.5em + 0.94rem);
    // background-image: url('./../../../images/svg/alert-circle.svg');
    // background-repeat: no-repeat;
    // background-position: right calc(0.375em + 0.235rem) center;
    // background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
  }

  .select-down-arrow {
    i {
      color: $primary-color;
    }
  }

}

.role-error {
  &.invalid-feedback {
    display: block;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        display: flex;
        align-items: center;

        &.inactive {
          &.active {
            color: $white;
            background: $primarygradient;
            i {
              color: rgb(241, 18, 0);
              padding: 0;
            }
          }
        }

        span {
          font-weight: 500;
        }
      }
    }
  }
}

tbody {
  .collapse-heading {
    display: flex;
    text-align: center;

    h5 {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .collapse-data {
    display: flex;
    text-align: center;

    p {
      width: 100%;
      margin-bottom: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }
}


.table tr td {
  padding: 8px 20px;
  vertical-align: middle;
}

.btn-group {
  width: 100%;

  .btn {
    &.select {
      background: $gray-600;
      color: #ffffff;
    }
  }
}

.cargoType-component {
  .card {
    -webkit-box-shadow: 1px -1px 10px 0px #dddcdd91;
    -moz-box-shadow: 1px -1px 10px 0px #dddcdd91;
    box-shadow: 1px -1px 10px 0px #dddcdd91;
    border-radius: 10px;

    .form-group {
      .btn-danger {
        &.close-btn {
          position: absolute;
          right: 0;
          z-index: 1;
        }
      }

      .selector {
        display: flex;

        input {
          appearance: none;
          display: none;
        }

        label {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: inherit;
          width: 40px;
          height: 35px;
          text-align: center;
          overflow: hidden;
          transition: linear 0.3s;
          color: #6e6e6edd;
          border-radius: 5px;
          margin-right: 10px;
          border: 1px solid #d3cece;
          cursor: pointer;
        }

        input:checked[type="radio"]+label {
          background-color: $primarygradient;
          color: #ffffff;
          font-weight: 700;
          transition: 0.3s;
          border: 1px solid $primary-color;
        }

        .HCOT {
          width: 60px;
        }
      }


      .field_error {
        color: $error-color;
        font-size: 80%;
      }

      &:has(.field_error) {
        .container-item {
          .required-field {
            background: $error-bg;
            border: 1px solid $error-color;
            padding-right: calc(1.5em + 0.94rem);
            background-image: none;
            // background-image: url('./../../../images/svg/alert-circle.svg');
            // background-repeat: no-repeat;
            // background-position: right calc(0.375em + 0.235rem) center;
            // background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
          }
        }

        .selector {
          .container-item {
            .required-field {
              border: 1px solid #d3cece;
              background: white;
              background-image: none;
            }

            label {
              padding: 0;
            }
          }
        }
      }

    }
  }
}

.react-datepicker-popper {
  z-index: 2;
}

.select-search-container {
  .select-search-options {
    margin-bottom: 0;
  }

  .select-search-option {
    height: auto;
    padding: 3% 20px;
  }
}

.main-content {
  overflow: unset;
}

.custom-header-css thead tr th div:last-child {
  min-height: auto !important;
}

// .custom-header-css.recent-quotes-table thead tr th div {
//   &:last-child {
//     display: none;
//   }
// }

.passwordChange-fields {
  input {
    padding-right: 34px;
  }

  label {
    position: relative;
    width: 100%;

    span {
      position: absolute;
      right: 12px;
      top: 42px;
      color: black;
    }
  }

  // input {
  //   width: 95%;
  // }

}

.ellipsis-text {
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  &.ellipsis-text {
    margin: 0 auto 1rem;
  }
}

//autho page
.account-pages {
  background: $white;
  background: url('../../../images/welcome-screen-bg.jpg') center no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
  
  .container {
    overflow: hidden;
  }

  .auth-logo {
    margin-bottom: 30px;

    img {
      width: 150px;
    }
  }

  .reset-password {
    overflow-y: scroll;
    height: 100vh;
    padding: 30px 0;
    width: 100%;
  }

  .copy-right {
    margin-top: 50px;

    p {
      font-size: 15px;
    }
  }

  .auth-card {
    background-color: $snow-drift;
    margin: 0;
    box-shadow: none;
    border-radius: 20px !important;
    .background-auth-page {
      background: $primarygradient;
      background-size: cover;
      padding: 15px 0;
      .auth-heading-text {
        color: #fff;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        h2 {
          margin-bottom: 0;
          font-weight: 800;
        }

        p {
          margin-bottom: 0;
          font-weight: 500;
        }
      }

      .logo-img {
        padding-top: 20PX;
        text-align: end;
      }
    }

    .timing {
      font-weight: 500;

      .remaining-time {
        font-weight: 800;
      }
    }

    .card-body {
      padding: 15px 30px 35px;
      background: #fff;
      input {
        border: none;
        font-weight: 500;
        color: $battleship-grey;
      }

      label {
        margin: 20px 0 6px;
        font-weight: 500;
        color: $dark-grey;
      }

      input {
        padding: 15px 22px;
        border-radius: 10px !important;
        box-shadow: none;

        &.is-invalid {
          border: 1px solid $input-error;
        }

        &.password-input {
          z-index: 0;
          border-right: 0px;
          padding-right: 40px;
          background-image: none;

        }

      }

      button {
        min-height: 53px;
      }

      #password-addon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 0;
        padding: 10px;
        background: transparent;

        i {
          font-size: 18px;
        }
      }

      .password-input+button {
        background: $white;
        border-left: none;

        &:hover {
          border: none;
        }

        &:focus {
          border: none;
        }

        i {
          opacity: .5;
        }
      }

      .password-input {
        width: 100%;
        border-radius: 10px !important;

        &.is-invalid {
          border: 1px solid $input-error;
        }

        &.is-invalid+button {
          background: transparent;

          &:hover {
            border: 0;

          }
        }
      }

      .forgot-password {
        margin-top: 9px;

        a {
          color: $dark-grey;
          font-weight: 500;
          text-decoration: underline !important;
        }
      }

      ::placeholder{
        color: $gray-400;
      }

      .resend-code {
        color: $primary-color;
        font-weight: 500;
        min-height: auto;
      }

      .primary-button {
        margin-top: 27px;
      }

      .go-back {
        margin: 19px auto 0;
        width: max-content;
        font-weight: 500;
        color: $dark-grey;
      }
    }
  }
  .error-img{
    text-align: center;
    img{
      max-width: 460px;
    }
  }
}

.view-quote {
  .container-head {
    div {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $border-color-line;

      p {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: $dark-grey;

      }
    }
  }

  .avater {
    align-items: center;
    margin-bottom: 20px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light-primary;
      margin-right: 10px;

      i {
        color: $primary-color;
      }
    }
  }

  .action-btn {
    a {
      display: flex;
      align-items: center;
    }
  }

  .container-body {
    div {
      display: flex;
      justify-content: space-between;

      p {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: $battleship-grey;
        padding: 6px 0;
      }
    }
  }

  a {
    color: $battleship-grey;
  }

  label {
    font-size: 14px;
    font-weight: 600;
  }

  textarea {
    resize: none;
    min-height: 120px;
  }

  .pagination {
    margin-bottom: 37px;
  }

  table {
    thead {
      th {
        &:nth-last-child(2) {
          text-align: center;
          padding-right: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-last-child(2) {
            padding-right: 0;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  p {
    margin-bottom: 0;
  }

 
}
.quotes-details {

  .form-label {
    font-weight: 700;
  }

  .form-control {
    font-weight: 500;
    box-shadow: #d1dcdc 2px 3px 4px;

    &.box-shadow-none {
      box-shadow: none;
    }
  }

  .table-responsive {
    .container-details-list {
      min-width: 900px;
      width: 100%;

      thead {
        border-bottom: 1px solid $battleship-grey;
      }
  
      th {
        text-align: left;
        
        p {
          width: 100%;
        }
      }
      
      td {
        text-align: left;
    
        p {
           width: 100%;
        }
      }
    
      tbody {
        tr {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .textarea {
    height: 151px;
    background: $white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
  -webkit-text-fill-color: $battleship-grey;
}


//primary-color
.primary-color {
  color: $primary-color;
}

@media screen and (max-width: 480px) {
  .account-pages {
    .auth-card {

      .background-auth-page {
        .auth-head {
          position: relative;

          .auth-heading {
            width: 100%;
            z-index: 1;

            .auth-heading-text {
              padding: 45px 30px;
            }
          }

          .logo-img {
            width: 100%;
            position: absolute;
          }
        }
      }
    }
  }
}

.drop-down {
  // margin-right: 20px;

  .user-dropdown {
    padding: 0 !important;
    min-width: 180px;
    width: 100%;


    [class*="-control"] {
      height: 100%;
      background: transparent !important;
      border-radius: 10px;
      border: none;
      padding-left: 5px;
      padding-right: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      width: 180px;
    }

    [class*="-indicatorSeparator"] {
      display: none;
    }
  }
}