.custom_select__control {
    border: 0 !important;
    min-height: 46px;
    background: $white;

    .custom_select__value-container {
        padding: 10px 15px;
    }
}

.custom_select__menu {

    .custom_select__option {
        padding: 14px 20px;
        font-weight: 500;
        // color: $darkcharcoal;
        text-transform: capitalize;
        font-size: 13px;

        &:hover {
            background-color: #FFE8E3 !important;
            color: $primary-color;

        }

        .custom_select__option.custom_select__option--is-selected.custom_select__option--is-focused {
            background-color: $primary-color !important;
            color: $white;
        }
    }
}

.drop-down {
    [class*="-indicatorSeparator"] {
        display: none;
    }

    [class*="-is-selected"] {
        background-color: $primary-color !important;
        color: $white;
    }

    [class*="-menu"] {
        border-radius: 10px;
        border: 0;
        box-shadow: 0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

        [class*="-option"] {
            cursor: pointer;

            &:hover {
                background-color: #FFE8E3 !important;
                color: $primary-color;
            }
        }

        [class*="-is-focused"] {
            background-color: #FFE8E3 !important;
            color: $primary-color !important;
        }

        [class*="-MenuList"] {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    [class*="-control"] {
        height: 100%;
        border-radius: 10px;
        border: none;
        padding-left: 5px;
        padding-right: 5px;
        white-space: nowrap;
        text-overflow: clip;
        width: 100%;
        cursor: pointer;
        background: $content-bg;
    }
    [class*="-singleValue"]{
        text-transform: capitalize;
    }
    .is-invalid {
        border: 1px solid $error-color;
        
        [class*="-control"] {
            background: $error-bg;
        }

        [class*="-indicator"] {
            color: $error-color;
        }
    }
}

// Color Option Two
.custom-input {
    .drop-down {
        [class*="-control"] {
            background: red;
        }

        [class*="-menu"] {
            background: red;
        }
    }
}