//
//  Sweetalert2
//

/* CSS Switch */
input[switch] {
  display: none;
  + label {
    font-size: 1em;
    line-height: 1;
    width: 42px;
    height: 23px;
    background-color: $gray-400;
    background-image: none;
    border-radius: 2rem;
    padding: 0.16667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    font-weight: $fw-medium;
    transition: all 0.1s ease-in-out;
     
    // &:before {
    //   color: $dark;
    //   content: attr(data-off-label);
    //   display: block;
    //   font-family: inherit;
    //   font-weight: 500;
    //   font-size: 12px;
    //   line-height: 21px;
    //   position: absolute;
    //   right: 1px;
    //   margin: 3px;
    //   top: -2px;
    //   text-align: center;
    //   min-width: 1.66667rem;
    //   overflow: hidden;
    //   transition: all 0.1s ease-in-out;
    // }

    &:after {
      content: '';
      position: absolute;
      left: 3px;
      background-color: $gray-200;
      box-shadow: none;
      border-radius: 2rem;
      height: 17px;
      width: 17px;
      top: 3px;
      transition: all 0.1s ease-in-out;
    }
  }

  &:checked + label {
    background-color: $primary;
  }
}

input[switch]:checked + label {
  background-color: $primary;
  // &:before {
  //   color: $white;
  //   content: attr(data-on-label);
  //   right: auto;
  //   left: 3px;
  // }

  &:after {
    left: 21px;
    background-color: $gray-200;
  }
}

input[switch="bool"] + label {
  background-color: $danger;
}
input[switch="bool"] + label:before,input[switch="bool"]:checked + label:before,
input[switch="default"]:checked + label:before{
  color: $white;
}

input[switch="bool"]:checked + label {
  background-color: $success;
}

input[switch="default"]:checked + label {
  background-color: #a2a2a2;
}

input[switch="primary"]:checked + label {
  background-color: $primary;
}

input[switch="success"]:checked + label {
  background-color: $success;
}

input[switch="info"]:checked + label {
  background-color: $info;
}

input[switch="warning"]:checked + label {
  background-color: $warning;
}

input[switch="danger"]:checked + label {
  background-color: $danger;
}

input[switch="dark"]:checked + label {
  background-color: $dark;
  &:before {
    color: $light;
  }
}

.square-switch{
  margin-right: 7px;
  input[switch]+label, input[switch]+label:after{
    border-radius: 25px;
  }
}
