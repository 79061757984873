// hr {
//   display: block; 
//   border: 1px solid $userRole;
//   width: 100%;
// }
.userRole {
  color: $userRole;
  text-align: right;
  // font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

.quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $userRole;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .limit {
    font-size: 16px;
    font-weight: 400;
  }
}

.count {
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 10px;
  display: flex;
  width: 200px;
  height: 100px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.buttons {
  display: flex;
  min-width: 100px;
  min-height: 100px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 2px solid $black;
  background: $whiteColor;
  cursor: pointer;
}

// .printBtn {
//   color: $whiteColor;
//   text-align: right;
//   //   font-family: Lato;
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   display: flex;
//   width: 230px;
//   height: 60px;
//   justify-content: center;
//   align-items: center;
//   flex-shrink: 0;
//   border-radius: 18px;
//   background: linear-gradient(90deg, #183fea 0%, #1632ac 100%);
//   margin-top: 250px;
//   margin-top: 250px;
//   margin-bottom: 140px;
// }

.print-qr {
  .card {
    height: 100vh;
  }

  .row {
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .print-count {
    padding-bottom: 60px;
    margin-bottom: 60px;
  }

  .primary-button {
    width: 230px;
    height: 60px;
    font-size: 22px;
  }
}

//Print tabs
.print-tabs {
  background: $whiteColor;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px !important;
  padding: 30px;

  .tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .tab-item {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s ease;
    position: relative;

    &.active {
      color: $gradientprimary;
      font-weight: 600;

      &::after {
        content: '';
        position: absolute;
        bottom: -12px;
        width: 100%;
        height: 4px;
        left: 0;
        border-radius: 5px;
        background: $gradientprimary;
      }

    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.tab-content {
  .print-qr {
    .campaign-details {
      text-align: center;
      font-size: large;
    }

    .card {
      box-shadow: none;
      background: transparent;
    }
  }

  .checkbox-grp {
    display: flex;
    width: 100%;
    margin-top: 25px;
    justify-content: center;
    padding-top: 20px;
    gap: 20px;
  }

  .form-check {
    display: flex;

    label {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      display: inline-block;
      padding-left: 10px;
      position: relative;
      top: 4px;
    }

    .form-check-input[type="radio"] {
      border-radius: 50%;
      width: 23px;
      height: 23px;

    }

    .form-check-input {
      &:checked {
        background-color: $gradientprimary;
        border-color: $gradientprimary;
      }
    }

  }

  .sentqr_group {
    margin: 0 auto;
    padding-top: 45px;

    .inputGroup {
      display: flex;
      align-items: center;
      flex-direction: column;

      .inputField {
        width: 350px;
      }

      .invalid-feedback {
        width: 350px;
      }

      .er-message {
        width: 350px;
        padding-left: 18px;
        position: relative;
        color: #f46a6a;
        font-size: 80%;
        margin-top: 0.25rem;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          background: url('../../../images/svg/alert-circle.svg');
          background-size: cover;
        }
      }
    }

    .primary-button {
      width: 120px;
      height: 40px;
      font-size: 16px;
    }
  }
}

.send-qr {
  .buttons {
    width: 60px;
    height: 60px;
    min-width: unset;
    min-height: unset;
    border-radius: 12px;

    img {
      width: 25px;
    }

    +.count {
      height: unset;
      font-size: 38px;
      width: 130px;
    }
  }
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=tel] {
  width: 350px;
}