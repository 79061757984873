.form-group label:before {
    content: "";
    --webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #000000;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
}

.form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 10px;
    width: 12px;
    height: 22px;
    border: solid #1b41ea;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    cursor: pointer;
}