.rmdp-container {
    .date-input {
        height: 40px;
        padding: 0 15px 0 40px;
        border-radius: 12px;
        background-color: $search-field;
        border-color: transparent;
    }

    .rmdp-wrapper {
        .rmdp-calendar {
            .rmdp-header {
                .rmdp-arrow-container {
                    &:hover {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .rmdp-arrow {
                        border-color: $black;
                        height: 8px;
                        width: 8px;
                    }
                }
            }

            .rmdp-day-picker {
                .rmdp-week {
                    .rmdp-week-day {
                        color: $gradientsecondary;
                    }

                    .rmdp-day.rmdp-selected span:not(.highlight) {
                        background: $primarygradient;
                        // primarygradientLight
                    }

                    .rmdp-day.rmdp-today span {
                        background: $primarygradientLittleDark;
                    }

                    .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
                        background: $primarygradientLight;
                    }
                }
            }
        }
    }
    .rmdp-ep-arrow {
        border-color: transparent;
    }
}

.date-picker {
    position: relative;

    .calendar-icon {
        position: absolute;
        top: 13px;
        left: 15px;
        filter: invert(1);
    }
}

.settings-card {
    .settings-conatiner{
        display: flex;
        gap: 40px;
    }
    .rmdp-container {
        width: 100%;
    }
}