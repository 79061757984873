@media print {
  .print-container {
    font-family: $font-family-sans-serif;
    padding: 35px;
    position: relative;
    background: #ffffff;
    max-width: 350px;
    height: 489px;
    text-align: center;
    margin-bottom: 15px;
    .p-header {
      .print-logo {
        text-align: center;
        padding: 15px 0;

        img {
          width: 178px;
        }
      }

      h2 {
        color: #000000;
        text-align: center;
        font-family: $font-family-sans-serif;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 8px;
        margin: 0;
      }

      span {
        color: #000000;
        font-size: 10px;
      }
    }

    .qr-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .qt-code {
        width: 150px;
        height: 150px;
        margin: 13px 0;
        // border: 1px solid #cccccc78;
      }

      p {
        font-size: 8px;
        color: #000000;
        text-align: center;
        margin: 0;
      }
    }

    .print-footer {
      padding-top: 21px;
      font-family: $font-family-sans-serif !important;
      h3 {
        color: #000000;
        text-align: center;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        padding-bottom: 10px;
        margin: 0;
      }
      
      p {
        color: #000000;
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }
}
