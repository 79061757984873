hr {
  display: block;
  border: 1px solid $userRole;
  width: 100%;
}

.userRole {
  color: $userRole;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

// .buttons {
//   display: flex;
//   max-width: 40px;
//   max-height: 40px;
//   padding: 8px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   gap: 8px;
//   flex-shrink: 0;
//   border-radius: 12px;
//   border: 1px solid $sidebar-menu-item-color;
//   cursor: pointer;
// }
.editbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $sidebar-menu-item-color;
  padding: 10px 15px;
  cursor: pointer;
}

.setgap {
  margin: 0 24px;
}

.tdEdit {
  border: 1px solid $sidebar-menu-item-color;
  width: 50%;
  background-color: transparent;
}

.inputTag {
  border: none;
  width: 50%;
  background-color: transparent;
}

.manage-prize {
  .badge-design {
    min-width: 60px;
  }

  .img-thumbnail {
    width: 100%;
  }

  .avatar-container {
    .avatar {
      position: relative;
      width: 50px;

      .sliding-container {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 25px;
        opacity: 0;
        transform: translateX(-50%);
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }

      &:hover .sliding-container {
        img {
          filter: invert(1);
        }

        opacity: 1;
        visibility: visible;
        background:#000000ab;
      }
    }
  }

  .edit-icon,
  .view-icon {
    margin-right: 5px;
  }

  .add-button {
    .btn-common {
      padding: 20px;
    }
  }

  .btn-common {
    min-width: 80px;
  }

  .table {
    td {
      &:nth-child(6) {
        // width: 250px;
      }
    }
  }
}

.manage-prize-action {

  // .more-option {
  //   background: #F2F5F7;
  //   border: 0;
  //   color: #000;
  //   font-size: 20px;
  //   padding: 5px;
  //   @include box-size(30px, 30px);
  //   @include borderRadius_10;
  //   @include flex-center-vert;

  //   &:hover {
  //     color: #000;
  //     background: #F2F5F7 !important;
  //   }
  // }

  display: flex;
  align-items: center;
  width: 100%;

  .action {
    display: flex;
    gap: 15px;

    span {
      cursor: pointer;
      border: 1px solid $black;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
    }
  }
}


.adding-prize {

  .page-title-box {
    h4 {
      font-weight: 600;
      font-size: 24px !important;
      line-height: 20px;
      color: #393939;
      margin: 0;
    }
  }

  .upload-container {
    border: 2px dashed grey;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    left: 0;
    @include border-radius(20px);
    height: 210px;

    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      left: 0;
      height: 100%;
      top: 0;
      cursor: pointer;
    }

    .upload-icon {
      @include box-size(60px, 60px);
      background: #E9F6EE;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      margin: 0 auto;
    }

    .upload-content {
      @include flex-center-column;
      justify-content: center;
      height: 100%;

      h5 {
        margin-top: 15px;
        margin-bottom: 8px;
      }
    }

  }

  .img-thumbnail {
    position: relative;
  }

  .upload-icon {
    position: absolute;
    left: 50%;
    bottom: 30%;
  }

  .inputField {
    width: 300px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    padding: 15px 22px;
    border: none;
    background: $search-field;
    display: flex;
    align-items: center;
  }

}

.segment-form {
  .diableSelect{
    cursor: not-allowed;
    opacity: 0.8;
  }
  .select-menu{
  }
  .segment-item{
    .error-message{
      color: $error-color;
    }
  }
}

.select-menu {
  margin-bottom: 15px;
  z-index: 3;

  [class*="-indicatorSeparator"] {
    display: none;
  }

  [class*="-is-selected"] {
    background: $primarygradient;
    color: $whiteColor;
  }

  [class*="-menu"] {
    cursor: pointer;
  }

  [class*="-control"] {
    font-size: 14px;
    border-radius: 10px;
    border: none;
    white-space: nowrap;
    text-overflow: clip;
    width: 100%;
    cursor: pointer;
    box-shadow: none;
    padding: 2px 0px 0 10px;
    background: $whiteColor;
    font-weight: 500;
    height: 46px;
  }
}

.custom-spin .card {
  padding: 30px;
  box-shadow: none;
  margin: 0;

  .spinWheel-container {
    padding: 30px;
    border-radius: 15px;
    background: $search-field;
    height: 100%;
  }
}

.segment-item {
  margin-bottom: 15px;

  .form-control {
    background: $white;
    padding: 10px 19px;
  }

  .segment-img {
    color: transparent;
    position: relative;
    padding: 9px 23px;
    width: 35px;
    border-radius: 8px;
    height: 45px;
    background: $white;
    cursor: pointer;
  }

  input.segment-img[type=file] {
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 24px;
      height: 24px;
      background: url('../../../images/svgicons/image-icon.svg') no-repeat;
    }

    &::file-selector-button {
      display: none;
    }
  }
}

.segment-view {
  @include flex-center;
  // transform: scale(0.80);

  #myCanvas {
    width: 100%;
    height: 100%;
  }
}

//manage-campaign
.manage-campaign {
  .settings-card {
    margin: 30px;

    &.card {
      box-shadow: none;
      background: $search-field;
    }

    .form-control {
      background: $whiteColor;
    }
  }

  .accordion-item {
    .disabled-accordion{
      pointer-events: none;
      opacity: 0.4;
    }
    margin-bottom: 20px;
    border-radius: 13px;

    .accordion-button {
      font-size: $fs-medium;
      font-weight: 600;
      color: $tiltes-bg;
      padding: 23px;

      &.collapsed {
        font-weight: 500;
      }

      &:not(.collapsed) {
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
      }
    }

    &:not(:first-of-type) {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .accordion-body {
    padding: 0;
  }

  .manage-prize {
    .card {
      box-shadow: none;
      margin: 30px;
      background: $search-field;
    }
  }
}

.campaign-list {
  @include justify-between;
  flex-direction: row;
  padding: 30px;
}

.compain-drop {
  [class*="-control"] {
    width: 250px;
    background: $search-field;
  }

  [class*="-container"] {
    margin-bottom: 0;
  }
}