// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  border-radius: 25px !important;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
  background: $white;
}
ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

// Manage Card Stayle
.manage-card {
  .profile {
    @include box-size(35px, 35px);
    @include borderRadius_50;
    background: #DBE8F0;
    @include flex-center;
    position: relative;
    .active-status{
      @include box-size(10px, 10px);
      @include borderRadius_50;
      position: absolute;
      bottom: 0px;
      right: 0px;
      border: 1px solid $whiteColor;
    
   }
   &.active{
    border:1px solid $active-color;
    .active-status{
      background:$active-color;
    }
   }
   &.inactive{
    border:1px solid $deactive-color;
    .active-status{
      background:$deactive-color;
    }
   }
    
  }
  hr{
    margin: 0;
    border-top: 1px solid rgba(225, 225, 225, 0.50);
  }
.crd-details {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  span  {
      font-size: $lite;
      font-weight: $regular;
      color: #999;
    }
  p {
      font-size: $fs-medium;
      font-weight: $semibold;
      color: #2A3547;
      margin: 0;
    }
}
}

.more-option {
  background: transparent;
  border: 0;
  color: #000;
  font-size: 23px;
  padding: 0;
  &:hover{
    color: #000;
    background: transparent;
  }
}


// Blog Card Style
.thumbnail-img{
 
  position: relative;
  img{
    object-fit: cover;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 100%;
    height: 100%;
  }
}
.tip-card{
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 15px;
  .tip-group{
    display: grid;
    grid-template-columns: 25% 75%;
  }
&.inactive{
  opacity: 0.6;
}
}
.tip-content{
  position: relative;
  .blog-header{
    ul{
      @include justify-between;
      li{
        margin-right: 20px
      }
    }
    @include justify-between;
  }
  .tips-auth-info{
    padding-top: 10px;
    width: 60%;
    @include justify-between;

  }
}