.advisors-screen{
     .uaermanage_group{
        margin-top: 15px;
        padding-top: 15px;
        display:  grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 18px;
    }

}
//view-advisor 
.view-advisor{
    .name-head{
        h5{
            font-size:  $xxlarge;
            color: #2A3547;
            font-weight: $medium; 
        }
    }
    .manage-card {
        .profile{
            @include box-size(55px, 55px);
            span{
                font-size: $xxlarge;
                font-weight: $semibold;
            }
        }
        .crd-details {
            grid-template-columns: repeat(4, 1fr);
            padding-bottom: 35px;
        }
    }
    .advisor-body{
        padding: 35px;
    }
    .view-status{
        min-width: 110px;
        padding: 7px 15px;
        text-align: center;
        @include borderRadius_30;
        span{
            color: #fff;
            font-weight: $medium;
            font-size: $lite;
        }
        &.active{
            background: $useractive-bg;
            span{
                color: $useractive-txt;
            }
        }
        &.inactive{
            background: $userinactive-bg;
            span{
                color: $userinactive-txt;
            }
        }
    }
}
.text-area{
    height: 100px;
}